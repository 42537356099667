<template lang="pug">
div.downpayments-costs.row.justify-content-between
  .col-xl-7
    .downpayment-sliders-wrapper
      .downpayment-slider
        .costs-list-subtitle
          strong {{ firstStepStyle.cash_payment_title }}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
          .costs-list-slider
            vue-slider(
              :min="minCashPayment"
              :max="maxCashPayment"
              v-model="cashPayment"
              :dotSize="slider.dotSize"
              :height="slider.height"
              :silent="true"
              :contained="true"
              :drag-on-click="true"
              :tooltip="'always'"
              @drag-end="sliderChange($event)"
            )
              template(v-slot:tooltip)
                div.custom-tooltip-price {{ formatter2.price }} kr
                div.custom-tooltip-percents {{ formatter2.cashPayment }} %

      .downpayment-slider
        div(v-if="getResidualVisibility")
          .costs-list-subtitle(v-if="getResidualVisibility")
            strong {{ firstStepStyle.residual_title}}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
            .infotooltip(@click="showPopup")
              .infotooltip-content {{ $t("steps.residual.info") }}

        .costs-list-slider(v-if="getResidualVisibility")
          vue-slider(
            :min="minResidual"
            :max="maxResidual"
            v-model="residual"
            :dotSize="slider.dotSize"
            :height="slider.height"
            :silent="true"
            :contained="true"
            :drag-on-click="true"
            :tooltip="'always'"
            @drag-end="sliderChange($event)"
          )
            template(v-slot:tooltip)
              div.custom-tooltip-price {{ formatter1.price }} kr
              div.custom-tooltip-percents {{ formatter1.residual }} %

  .col-xl-4
    div.downpayment-prices.d-flex.flex-wrap
      div.price-container
        .costs-list-subtitle {{ firstStepStyle.total_price_title }} {{ !getIsVatIncluded ? `(${$t('exmoms')})` : '' }}
        .m-t-15.big
          strong {{ formatPrice(netPriceInclAccessories) }} {{ currencySymbol }}
      div.price-container
        .costs-list-subtitle {{ firstStepStyle.credit_amount_title }}
        .m-t-15.big
          strong {{ formatPrice(totalCreditAmount) }} {{ currencySymbol }}
      div.price-container
        .costs-list-subtitle {{ firstStepStyle.interest_title }}
        .m-t-15.big
          strong {{ rate }} %
      div.price-container(v-if="effectiveRate")
        .costs-list-subtitle {{ firstStepStyle.effective_interest_title }}
        .m-t-15.big
          strong {{ effectiveRate }} %

  .d-flex.justify-content-between.align-items-center.w-100
    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="false"
    )

  popup.transparent(:show="isPopup" @click="closePopup" :textcolor="firstStepStyle.info_popup_text_color" :bgcolor="firstStepStyle.info_popup_bg_color")
    .content {{ firstStepStyle.residual_info_popup_text }}

</template>

<script>
import CostsMixin from '@/mixins/CostsMixin'
import CostsSliderMixin from '@/mixins/CostsSliderMixin'
import {mapGetters} from "vuex";

export default {
  mixins: [
    CostsMixin,
    CostsSliderMixin,
  ],
  data: () => ({
    isPopup: false,
  }),
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
    Popup: () => import('@/components/helps/Popup'),
  },
  computed: {
    ...mapGetters('financing', [
        'netPriceInclAccessories',
        'totalCreditAmount'
    ]),
    costPrice() {
      return this.getFormCostPrice('Down payment')
    },
    formatter1() {
      return {
        price: this.formatPrice(this.calculatedResidualPrice),
        residual: this.residual
      }
    },
    formatter2() {
      return {
        price: this.formatPrice(this.calculatedCashPaymentPrice),
        cashPayment: this.cashPayment
      }
    },
  },
  methods: {
    showPopup() {
      this.$set(this.$data, 'isPopup', true);
    },
    closePopup() {
      this.$set(this.$data, 'isPopup', false);
    },
  },
}
</script>